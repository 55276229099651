<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">{{
            $t("frontapi.endpoints.dialog_model.title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-if="errors.length > 0">
              <v-col cols="12">
                <div
                  class="alert fade alert-danger"
                  role="alert"
                  v-show="errors.length"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="modelEndpointConfiguration.is_cached"
                  :label="isCachedLabel"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="modelEndpointConfiguration.cache_ttl"
                  :label="cacheTTLLabel"
                  :hint="cacheTTLHint"
                  type="number"
                  :rules="[
                    v =>
                      (v !== undefined && v !== null) ||
                      $t(
                        'frontapi.endpoints.dialog_model.fields.cache_ttl.rule'
                      )
                  ]"
                ></v-text-field>
                <strong class="text-success">{{ formatTime(modelEndpointConfiguration.cache_ttl) }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="modelEndpointConfiguration.cache_grace_period"
                  :label="cacheGracePeriodLabel"
                  :hint="cacheGracePeriodHint"
                  type="number"
                  :rules="[
                    v =>
                      (v !== undefined && v !== null) ||
                      $t(
                        'frontapi.endpoints.dialog_model.fields.cache_grace_period.rule'
                      )
                  ]"
                ></v-text-field>
                <strong class="text-success">{{ formatTime(modelEndpointConfiguration.cache_grace_period) }}</strong>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="close">{{
            $t("common.close")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save">{{
            $t("common.save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  CLOSE_DIALOG,
  REFRESH,
  ENDPOINT_CONFIGURATION_PUT_MODEL,
  ENDPOINT_CONFIGURATION_PULL_LIST
} from "@/core/services/store/endpoint_configuration.module";

export default {
  data() {
    return {
      valid: true
    };
  },
  methods: {
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600).toString().padStart(2, "0");
      const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
      const s = (seconds % 60).toString().padStart(2, "0");
      return `${h}:${m}:${s}`;
    },
    async save() {
      await this.$store.dispatch(ENDPOINT_CONFIGURATION_PUT_MODEL, {
        id: this.modelEndpointConfiguration.id,
        data: this.modelEndpointConfiguration
      });
      this.close();
      await this.$store.dispatch(REFRESH);
      await this.$store.dispatch(ENDPOINT_CONFIGURATION_PULL_LIST);
    },
    close() {
      this.$store.dispatch(CLOSE_DIALOG);
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.dialog || this.show_dialog;
      },
      set(value) {
        this.dialog = value;
        this.$store.dispatch(CLOSE_DIALOG);
      }
    },
    isCachedLabel() {
      return this.$t("frontapi.endpoints.dialog_model.fields.is_cached.label");
    },
    cacheTTLLabel() {
      return this.$t("frontapi.endpoints.dialog_model.fields.cache_ttl.label");
    },
    cacheTTLHint() {
      return this.$t("frontapi.endpoints.dialog_model.fields.cache_ttl.hint");
    },
    cacheGracePeriodLabel() {
      return this.$t(
        "frontapi.endpoints.dialog_model.fields.cache_grace_period.label"
      );
    },
    cacheGracePeriodHint() {
      return this.$t(
        "frontapi.endpoints.dialog_model.fields.cache_grace_period.hint"
      );
    },
    ...mapState({
      errors: state => state.endpoint_configuration.errors,
      message: state => state.endpoint_configuration.message,
      hasMessage: state => state.endpoint_configuration.hasMessage,
      show_dialog: state => state.endpoint_configuration.showDialog
    }),
    ...mapGetters(["modelEndpointConfiguration"])
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
