<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-wrap py-3">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("frontapi.endpoints.title") }}
          <span class="d-block text-muted pt-2 font-size-sm">{{
            $t("frontapi.endpoints.subtitle")
          }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <edit_dialog />
        <a href="https://swagger.upsearch.cz/" target="_blank" class="btn btn-info">{{ $t("frontapi.endpoints.actions.swagger") }}</a>
        <!--end::Button-->
      </div>
    </div>
    <div class="card-body">
      <div
        id="kt_datatable_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :search="search"
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <template v-slot:item.is_cached="{ item }">
                <div v-if="item.is_cached">
                  <strong class="badge badge-success">{{
                    $t("common.yes")
                  }}</strong>
                </div>
                <div v-else>{{ $t("common.no") }}</div>
              </template>
              <template v-slot:item.cache_ttl="{ item }">
                <div :class="item.is_cached ? 'badge badge-success' : ''">
                    {{ formatTime(item.cache_ttl) }}
                </div>
              </template>
              <template v-slot:item.cache_grace_period="{ item }">
                <div :class="item.is_cached ? 'badge badge-success' : ''">
                  {{ formatTime(item.cache_grace_period) }}
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <a
                  @click="editItem(item)"
                  href="#"
                  class="btn btn-primary font-weight-bolder mr-2"
                >
                  {{ $t("frontapi.endpoints.dialog_model.title") }}</a
                >
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  {{ $t("common.nodata") }}
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import edit_dialog from "./edit_dialog.vue";
import { mapGetters, mapState } from "vuex";
import {
  ENDPOINT_CONFIGURATION_PULL_LIST,
  ENDPOINT_CONFIGURATION_PULL_MODEL
} from "@/core/services/store/endpoint_configuration.module";

export default {
  components: { edit_dialog },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        {
          text: this.$t("frontapi.endpoints.list.table.headers.path"),
          value: "path"
        },
        {
          text: this.$t("frontapi.endpoints.list.table.headers.method"),
          value: "request_method"
        },
        {
          text: this.$t("frontapi.endpoints.list.table.headers.is_cached"),
          value: "is_cached"
        },
        {
          text: this.$t("frontapi.endpoints.list.table.headers.cache_ttl"),
          value: "cache_ttl"
        },
        {
          text: this.$t(
            "frontapi.endpoints.list.table.headers.cache_grace_period"
          ),
          value: "cache_grace_period"
        },
        {
          text: this.$t("frontapi.endpoints.list.table.headers.actions"),
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.endpoint_configuration.errors,
      message: state => state.endpoint_configuration.message,
      hasMessage: state => state.endpoint_configuration.hasMessage,
      items: state => state.endpoint_configuration.list,
      loading: state => state.endpoint_configuration.loading
    }),
    ...mapGetters(["currentProject"])
  },
  methods: {
    formatTime(seconds) {
      const h = Math.floor(seconds / 3600).toString().padStart(2, "0");
      const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
      const s = (seconds % 60).toString().padStart(2, "0");
      return `${h}:${m}:${s}`;
    },
    editItem(item) {
      this.$store.dispatch(ENDPOINT_CONFIGURATION_PULL_MODEL, item.id);
    },
    reloadData() {
      this.$store.dispatch(ENDPOINT_CONFIGURATION_PULL_LIST);
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>
