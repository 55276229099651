var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("frontapi.endpoints.dialog_model.title")))])]),_c('v-card-text',[_c('v-container',[(_vm.errors.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.length),expression:"errors.length"}],staticClass:"alert fade alert-danger",attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":_vm.isCachedLabel},model:{value:(_vm.modelEndpointConfiguration.is_cached),callback:function ($$v) {_vm.$set(_vm.modelEndpointConfiguration, "is_cached", $$v)},expression:"modelEndpointConfiguration.is_cached"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.cacheTTLLabel,"hint":_vm.cacheTTLHint,"type":"number","rules":[
                  function (v) { return (v !== undefined && v !== null) ||
                    _vm.$t(
                      'frontapi.endpoints.dialog_model.fields.cache_ttl.rule'
                    ); }
                ]},model:{value:(_vm.modelEndpointConfiguration.cache_ttl),callback:function ($$v) {_vm.$set(_vm.modelEndpointConfiguration, "cache_ttl", $$v)},expression:"modelEndpointConfiguration.cache_ttl"}}),_c('strong',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.formatTime(_vm.modelEndpointConfiguration.cache_ttl)))])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.cacheGracePeriodLabel,"hint":_vm.cacheGracePeriodHint,"type":"number","rules":[
                  function (v) { return (v !== undefined && v !== null) ||
                    _vm.$t(
                      'frontapi.endpoints.dialog_model.fields.cache_grace_period.rule'
                    ); }
                ]},model:{value:(_vm.modelEndpointConfiguration.cache_grace_period),callback:function ($$v) {_vm.$set(_vm.modelEndpointConfiguration, "cache_grace_period", $$v)},expression:"modelEndpointConfiguration.cache_grace_period"}}),_c('strong',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.formatTime(_vm.modelEndpointConfiguration.cache_grace_period)))])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("common.close")))]),_c('v-spacer'),_c('v-btn',{ref:"submit",attrs:{"color":"primary dark large"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }