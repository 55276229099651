var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header flex-wrap py-3"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("frontapi.endpoints.title"))+" "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v(_vm._s(_vm.$t("frontapi.endpoints.subtitle")))])])]),_c('div',{staticClass:"card-toolbar"},[_c('edit_dialog'),_c('a',{staticClass:"btn btn-info",attrs:{"href":"https://swagger.upsearch.cz/","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("frontapi.endpoints.actions.swagger")))])],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"dataTables_wrapper dt-bootstrap4 no-footer",attrs:{"id":"kt_datatable_wrapper"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('v-data-table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.is_cached",fn:function(ref){
var item = ref.item;
return [(item.is_cached)?_c('div',[_c('strong',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.$t("common.yes")))])]):_c('div',[_vm._v(_vm._s(_vm.$t("common.no")))])]}},{key:"item.cache_ttl",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.is_cached ? 'badge badge-success' : ''},[_vm._v(" "+_vm._s(_vm.formatTime(item.cache_ttl))+" ")])]}},{key:"item.cache_grace_period",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.is_cached ? 'badge badge-success' : ''},[_vm._v(" "+_vm._s(_vm.formatTime(item.cache_grace_period))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"btn btn-primary font-weight-bolder mr-2",attrs:{"href":"#"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t("frontapi.endpoints.dialog_model.title")))])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" "+_vm._s(_vm.$t("common.nodata"))+" ")])]},proxy:true}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }